import React, { useEffect, useRef, useState } from 'react';
import {
  VStack,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  HStack,
  Button,
  Select,
  FormHelperText,
  useToast,
  List,
  ListItem
} from '@chakra-ui/react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';
import { thousands_separators } from '../utils/formatCurrency';
import emailjs, { init, sendForm } from 'emailjs-com';

import { Datepicker } from './Datepicker';
import { format } from 'date-fns';
import { useProduce, useProduceTypes } from '../hooks/useProduceType';
import { useHandlers, useSuppliers } from '../hooks/useSuppliers';
import { SOURCING_PRODUCTS } from '../api/urls';
import { useMutation } from 'react-query';
import { useAxios } from '../hooks/useAxios';
import { useUsers } from '../hooks/useAgents';
import { useHistory } from 'react-router-dom';

const schema = yup.object().shape({
  supplier_id: yup.string().required(),
  produce_type_id: yup.string().required(),
  cost_per_kg: yup.number().required(),
  total_net_weight: yup.number().required(),
  // phonenumber: yup
  //   .string()
  //   .matches(
  //     /^(\+254|0|020|05){1}[ ]?[0-7]{1}([0-9]{1}[0-9]{1})[ ]?[0-9]{3}[ ]?[0-9]{3}/g,
  //     'Phone number is not valid'
  //   )
  //   .required(),
  served_by_id: yup.string().required()
});

init('user_Q1UjiMTtd9FAM5Us9Zo7C');

const Form = () => {
  const [data, setData] = useState({
    name: '',
    served_by: '',
    produce: '',
    phonenumber: '',
    weight: '',
    cost: '',
    total: '',
    date: '',
    location: '',
    lpo_date: ''
  });
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
    getValues,
    setValue
  } = useForm({
    mode: 'onBlur'
    // resolver: yupResolver(schema)
  });

  const products = useProduce();
  const [startDate, setStartDate] = useState(new Date());
  const [harvestDate, setHarvestDate] = useState(new Date());

  const toast = useToast();
  const axios = useAxios();
  const history = useHistory();
  const { data: supplier } = useSuppliers();
  const { data: handlers } = useHandlers();
  const { data: productTypes } = useProduceTypes();
  const { data: users } = useUsers();

  const formRef = useRef(null);
  const submitRef = useRef(null);

  const totalCost = watch('cost_per_kg') * watch('total_net_weight');

  const selectedSupplier = supplier?.find(
    supp => supp.id === watch('supplier_id')
  );
  const selectedHandler = handlers?.find(
    supp => supp.id === watch('served_by_id')
  );

  const selectedProduct = productTypes?.find(
    supp => supp.id == watch('product_type_id')
  );
  const formValues = getValues();

  useEffect(() => {
    if (formValues.supplier_id) {
      setData({
        ...data,
        ...{
          name: selectedSupplier?.display_name,
          served_by: selectedHandler?.display_name,
          produce: selectedProduct?.name,
          phonenumber: selectedSupplier?.contact_phonenumber,
          weight: getValues('total_net_weight'),
          cost: getValues('cost_per_kg'),
          total: totalCost,
          date: format(new Date(startDate), 'yyyy-MM-dd'),
          lpo_date: format(new Date(startDate), 'yyyyMMddHHmm'),
          location: selectedSupplier?.location
        }
      });
    }
  }, [
    selectedHandler,
    selectedProduct,
    selectedSupplier,
    getValues,
    totalCost,
    startDate
  ]);

  const sendSMS = () => {
    const payload = getValues();

    var config = {
      method: 'post',
      url: 'https://smssender-qpib6jdq0-shyrynaz.vercel.app/',

      data: {
        ...payload,
        date: format(new Date(startDate), 'yyyy-MM-dd HH:mm'),
        supplier: selectedSupplier?.display_name,
        handler: selectedHandler?.display_name
      }
    };

    axios(config)
      .then(function (response) {
        toast({
          title: 'sms sent successful.',
          status: 'success',
          duration: 5000,
          isClosable: true
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const sendEmail = e => {
    emailjs.send('supplier_sourcing', 'supplier_sourcing', data).then(
      function (response) {
        toast({
          title: 'Email sent successful.',
          status: 'success',
          duration: 5000,
          isClosable: true
        });
      },
      function (error) {
        toast({
          title: 'Unable to send Email',
          description: '',
          status: 'error',
          duration: 5000,
          isClosable: true
        });
      }
    );
  };

  const sourceProducts = async data => {
    const { data: response } = await axios.post(`${SOURCING_PRODUCTS}`, data);
    return response;
  };

  const { mutate, isLoading } = useMutation(sourceProducts, {
    onSuccess: response => {
      toast({
        title: 'Sourcing successful',
        status: 'success',
        duration: 5000,
        isClosable: true
      });
      if (data.name) {
        sendEmail();
        // sendSMS();
        reset();
      }
      history.push({
        pathname: '/success',
        state: response?.returned_resultset
      });
    },
    onError: () => {
      toast({
        title: 'Unable to complete your request. Please try again',
        status: 'error',
        duration: 5000,
        isClosable: true
      });
      history.push('/error');
    }
  });

  const onSubmit = values => {
    delete values.cost;
    delete values.weigth;
    delete values.name;
    delete values.served_by;
    delete values.phonenumber;
    confirmAlert({
      title: '',
      message: 'Click confirm to submit.',
      buttons: [
        {
          label: 'Confirm',
          onClick: () => {
            mutate({
              ...values,
              // source_date: new Date(startDate)
              harvest_ready_date: format(new Date(harvestDate), 'yyyy-MM-dd'),
              lpo_number: format(new Date(), 'yyyyMMddHHmm')
            });
          }
        },
        {
          label: 'Cancel',
          onClick: () => onclose
        }
      ],
      childrenElement: () => (
        <List spacing={3}>
          <ListItem>
            Supplier:{' '}
            {
              supplier?.find(supp => supp.id === values.supplier_id)
                ?.display_name
            }
          </ListItem>
          <ListItem>Location: {values.address}</ListItem>
          <ListItem>Produce: {data.produce}</ListItem>
          <ListItem>Cost Per Kg: {values.cost_per_kg}</ListItem>
          <ListItem>Total Weight: {values.total_net_weight}</ListItem>
          <ListItem>Total: {totalCost}</ListItem>
          <ListItem>Served by: {selectedHandler?.display_name}</ListItem>
          <ListItem>Date: {format(startDate, 'yyyy-MM-dd HH:mm')}</ListItem>
        </List>
      )
    });
  };

  return (
    <>
      <div
        style={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          paddingBottom: '2rem'
        }}
        onSubmit={event => {
          event.preventDefault();
          handleSubmit(onSubmit);
        }}
      >
        <VStack
          // divider={<StackDivider borderColor='gray.200' />}
          spacing={4}
          marginTop={30}
          align='stretch'
          width='80%'
        >
          <FormControl isInvalid={errors?.name?.message}>
            <FormLabel>Supplier Name</FormLabel>
            <Select
              placeholder='Select agent...'
              name='supplier_id'
              focusBorderColor='brand.800'
              {...register('supplier_id', { required: 'Supplier is required' })}
            >
              {supplier
                ?.sort((a, b) => a.display_name.localeCompare(b.display_name))
                .map(supplier => (
                  <option key={supplier.id} value={supplier?.id}>
                    {supplier?.display_name}
                  </option>
                ))}
            </Select>

            <FormErrorMessage>{errors?.supplier_id?.message}</FormErrorMessage>
          </FormControl>
          {selectedSupplier ? (
            <FormControl>
              <FormLabel>Location</FormLabel>
              <Input
                focusBorderColor='brand.800'
                disabled
                name='location'
                value={selectedSupplier?.location}
                placeholder='Enter supplier phone number'
                type='text'
              />
            </FormControl>
          ) : null}

          <FormControl isInvalid={errors?.product_type_id?.message}>
            <FormLabel>Produce Type</FormLabel>
            <Select
              placeholder='Select...'
              name='product_type_id'
              focusBorderColor='brand.800'
              {...register('product_type_id', {
                required: 'Produce type is required'
              })}
            >
              {productTypes
                ?.filter(prod => prod.id !== 4)
                ?.map(prod => (
                  <option key={prod.id} value={prod?.id}>
                    {prod?.name}
                  </option>
                ))}
            </Select>

            <FormErrorMessage>
              {errors?.product_type_id?.message}
            </FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={errors?.quality_score?.message}>
            <FormLabel>Quality score</FormLabel>
            <Select
              placeholder='Select...'
              name='quality_score'
              focusBorderColor='brand.800'
              {...register('quality_score', {
                required: 'Please provide a quality score'
              })}
            >
              <option value='5'>Very Good</option>
              <option value='4'>Good</option>
              <option value='3'>Average</option>
              <option value='2'>Bad</option>
              <option value='1'>Very Bad</option>
            </Select>

            <FormErrorMessage>
              {errors?.quality_score?.message}
            </FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={errors?.cost_per_kg?.message}>
            <FormLabel>Cost Per Kg</FormLabel>
            <NumberInput min={0}>
              <NumberInputField
                placeholder='Enter cost per KG'
                focusBorderColor='brand.800'
                name='cost_per_kg'
                {...register('cost_per_kg', { required: 'Cost is required' })}
              />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
            <FormErrorMessage>{errors?.cost_per_kg?.message}</FormErrorMessage>
          </FormControl>
          {/* write new commented code here */}
          <FormControl isInvalid={errors?.total_net_weight?.message}>
            <FormLabel>Total Net Weight</FormLabel>
            <NumberInput min={0}>
              <NumberInputField
                placeholder='Enter total net weight'
                focusBorderColor='brand.800'
                name='total_net_weight'
                {...register('total_net_weight', {
                  required: 'Net weight is required'
                })}
              />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
            <FormErrorMessage>
              {errors?.total_net_weight?.message}
            </FormErrorMessage>
            <FormHelperText>
              Total ksh. {thousands_separators(totalCost)}
            </FormHelperText>
          </FormControl>
          <FormControl isInvalid={errors?.male_casuals?.message}>
            <FormLabel>No of male casuals</FormLabel>
            <NumberInput min={0}>
              <NumberInputField
                focusBorderColor='brand.800'
                name='male_casuals'
                {...register('male_casuals', {
                  required: 'Number of male casuals is required'
                })}
              />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
            <FormErrorMessage>
              {errors?.total_net_weight?.message}
            </FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={errors?.female_casuals?.message}>
            <FormLabel>No of female casuals</FormLabel>
            <NumberInput min={0}>
              <NumberInputField
                focusBorderColor='brand.800'
                name='female_casuals'
                {...register('female_casuals', {
                  required: 'Number of female casuals is required'
                })}
              />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
            <FormErrorMessage>
              {errors?.total_net_weight?.message}
            </FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={errors?.served_by_id?.message}>
            <FormLabel>Sourced By</FormLabel>
            <Select
              placeholder='Select...'
              name='served_by_id'
              focusBorderColor='brand.800'
              {...register('served_by_id', {
                required: 'Served By is required'
              })}
            >
              {handlers?.map(user => (
                <option key={user.id} value={user?.id}>
                  {user?.name}
                </option>
              ))}
            </Select>

            <FormErrorMessage>{errors?.served_by_id?.message}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={errors?.date?.message}>
            <FormLabel>Date</FormLabel>
            <Datepicker
              startDate={startDate}
              setStartDate={setStartDate}
              name='date'
            />

            <FormErrorMessage>{errors?.date?.message}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={errors?.harvest_ready_date?.message}>
            <FormLabel>Next Ready Harvest Date</FormLabel>
            <Datepicker
              startDate={harvestDate}
              setStartDate={setHarvestDate}
              name='harvest_ready_date'
            />

            <FormErrorMessage>{errors?.date?.message}</FormErrorMessage>
          </FormControl>
          <Input
            style={{ opacity: 0 }}
            focusBorderColor='brand.800'
            name='total'
            type='text'
            value={totalCost.toFixed(3)}
          />
          <HStack>
            <Button
              onClick={handleSubmit(onSubmit)}
              width='50%'
              bg='brand.900'
              isLoading={isLoading}
              colorScheme='purple'
            >
              Submit
            </Button>
          </HStack>
        </VStack>
      </div>
    </>
  );
};

export default Form;
