import { useState } from 'react';

import {
  Flex,
  Heading,
  Input,
  Button,
  InputGroup,
  Stack,
  InputLeftElement,
  chakra,
  Box,
  Avatar,
  FormControl,
  InputRightElement,
  useToast
} from '@chakra-ui/react';
import { FaUserAlt, FaLock } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import { useMutation, useQueryClient } from 'react-query';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAxios } from '../hooks/useAxios';
import { LOGIN_URL } from '../api/urls';
const CFaUserAlt = chakra(FaUserAlt);
const CFaLock = chakra(FaLock);

const schema = yup.object().shape({
  email: yup.string().required(),
  password: yup.string().required()
});

export const LoginPage = () => {
  const [showPassword, setShowPassword] = useState(false);
  const queryClient = useQueryClient();
  const history = useHistory();
  const axios = useAxios();
  const { register, handleSubmit } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema)
  });

  const toast = useToast();

  const handleShowClick = () => setShowPassword(!showPassword);

  const loginUser = async data => {
    const { data: response } = await axios.post(`${LOGIN_URL}`, {
      username: data.email,
      password: data.password
    });
    return response;
  };

  const { mutate, isLoading } = useMutation(data => loginUser(data), {
    onSuccess: data => {
      const { message, access_token } = data;

      localStorage.setItem('token', access_token);
      localStorage.setItem('isAuthenticated', true);
      toast({
        title: message,
        status: 'success',
        duration: 5000,
        isClosable: true
      });
      queryClient.setQueryData('user', data.user);
      history.push('/sourcing');
    }
  });

  const onSubmit = data => mutate(data);

  return (
    <Flex
      flexDirection='column'
      width='100wh'
      height='100vh'
      backgroundColor='brand.900'
      justifyContent='center'
      alignItems='center'
    >
      <Stack
        flexDir='column'
        mb='2'
        justifyContent='center'
        alignItems='center'
      >
        <Avatar bg='purple.800' />
        <Heading color='brand.900'>Welcome</Heading>
        <Box minW={{ base: '90%', md: '468px' }}>
          <form
            onSubmit={event => {
              event.preventDefault();
              handleSubmit(onSubmit);
            }}
            id='form'
          >
            <Stack
              spacing={4}
              p='1rem'
              backgroundColor='whiteAlpha.900'
              borderRadius={10}
              boxShadow='md'
            >
              <FormControl>
                <InputGroup>
                  <InputLeftElement
                    pointerEvents='none'
                    children={<CFaUserAlt color='gray.300' />}
                  />
                  <Input
                    type='email'
                    name='email'
                    {...register('email', { required: true })}
                    placeholder='email address'
                  />
                </InputGroup>
              </FormControl>
              <FormControl>
                <InputGroup>
                  <InputLeftElement
                    pointerEvents='none'
                    color='gray.300'
                    children={<CFaLock color='gray.300' />}
                  />
                  <Input
                    type={showPassword ? 'text' : 'password'}
                    placeholder='Password'
                    name='password'
                    {...register('password', { required: true })}
                  />
                  <InputRightElement width='4.5rem'>
                    <Button h='1.75rem' size='sm' onClick={handleShowClick}>
                      {showPassword ? 'Hide' : 'Show'}
                    </Button>
                  </InputRightElement>
                </InputGroup>
              </FormControl>
              <Button
                borderRadius={0}
                onClick={handleSubmit(onSubmit)}
                isLoading={isLoading}
                type='submit'
                variant='solid'
                colorScheme='teal'
                bg='purple.800'
                width='full'
              >
                Login
              </Button>
            </Stack>
          </form>
        </Box>
      </Stack>
    </Flex>
  );
};
