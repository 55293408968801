import { useQuery } from 'react-query';
import { HANDLERS_URL, SUPPLIER_URL } from '../api/urls';
import { useAxios } from './useAxios';

export const useSuppliers = () => {
  const axios = useAxios();
  return useQuery(
    'suppliers',
    async () => {
      const { data } = await axios.get(`${SUPPLIER_URL}&limit=10000`);
      const { returned_resultset } = data;
      return returned_resultset;
    },
    {}
  );
};

export const useHandlers = () => {
  const axios = useAxios();
  return useQuery(
    'handlers',
    async () => {
      const { data } = await axios.get(HANDLERS_URL);
      const { returned_resultset } = data;
      return returned_resultset;
    },
    {}
  );
};
