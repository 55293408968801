import React from 'react';
import './App.css';
import { ChakraProvider, extendTheme, Flex } from '@chakra-ui/react';
import Navbar from './components/Navbar';
import { QueryClient, QueryClientProvider } from 'react-query';

import Form from './components/Form';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from 'react-router-dom';
import AxiosProvider from './context/AuthContext';
import { LoginPage } from './pages/Login';
import { useProduce } from './hooks/useProduceType';
import Success from './pages/Success';
import Error from './pages/Error';

function ProtectedRoute({ component: Component, ...restOfProps }) {
  const isAuthenticated = localStorage.getItem('isAuthenticated');
  console.log('called');

  return (
    <Route
      {...restOfProps}
      render={props =>
        isAuthenticated === true ? (
          <Component {...props} />
        ) : (
          <Redirect to='/' />
        )
      }
    />
  );
}
const queryClient = new QueryClient();

const colors = {
  brand: {
    900: '#2AB4AA',
    800: '#494395'
  }
};
const theme = extendTheme({ colors });
function App() {
  const isAuthenticated = localStorage.getItem('isAuthenticated');
  console.log(isAuthenticated, 'is auth');

  return (
    <Router>
      <AxiosProvider>
        <QueryClientProvider client={queryClient}>
          <ChakraProvider theme={theme}>
            <Navbar />
            <Switch>
              <Route exact path='/'>
                <LoginPage />
              </Route>
              <ProtectedRoute exact path='/success'>
                <Success />
              </ProtectedRoute>
              <ProtectedRoute exact path='/error'>
                <Error />
              </ProtectedRoute>
              <ProtectedRoute exact path='/sourcing'>
                <Form />
              </ProtectedRoute>

              <Redirect from='/' to={!!isAuthenticated ? '/sourcing' : '/'} />
            </Switch>
          </ChakraProvider>
        </QueryClientProvider>
      </AxiosProvider>
    </Router>
  );
}

export default App;
