import { useQuery } from 'react-query';
import { PRODUCT_URL, PRODUCT_URL_TYPE } from '../api/urls';
import { useAxios } from './useAxios';

export const useProduce = () => {
  const axios = useAxios();
  return useQuery(
    'products',
    async () => {
      const { data } = await axios.get(PRODUCT_URL);
      const { returned_resultset } = data;
      return returned_resultset;
    },
    {}
  );
};

export const useProduceTypes = () => {
  const axios = useAxios();
  return useQuery(
    'product-types',
    async () => {
      const { data } = await axios.get(PRODUCT_URL_TYPE);
      const { returned_resultset } = data;
      return returned_resultset;
    },
    {}
  );
};
