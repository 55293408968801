import React from 'react';
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Container,
  Button,
  List,
  ListItem
} from '@chakra-ui/react';
import { useHistory, useLocation } from 'react-router-dom';

const Success = () => {
  const history = useHistory();
  const location = useLocation();

  const { state } = location;

  console.log(location, 'location');
  return (
    <Container>
      <Alert
        status='success'
        marginTop='2'
        variant='subtle'
        flexDirection='column'
        alignItems='center'
        justifyContent='center'
        height='full'
        textAlign='center'
      >
        <AlertIcon boxSize='40px' mr={0} />
        <AlertTitle mt={4} mb={1} fontSize='lg'>
          Sourcing Successful
        </AlertTitle>
        <AlertDescription maxWidth='sm'>
          The following products have been sourced
        </AlertDescription>
        <List spacing={3}>
          <ListItem>
            Supplier: <strong>{state[0]?.supplier.display_name}</strong>
          </ListItem>

          <ListItem>
            Produce: <strong>{state[0]?.product_type?.name}</strong>
          </ListItem>
          <ListItem>
            Quality Score: <strong>{state[0]?.quality_score}</strong>
          </ListItem>
          <ListItem>
            Cost Per Kg: <strong>{state[0]?.cost_per_kg}</strong>
          </ListItem>

          <ListItem>
            Total Weight: <strong>{state[0]?.total_net_weight}</strong>
          </ListItem>
        </List>

        <Button
          colorScheme='purple'
          marginTop='2'
          variant='outline'
          onClick={() => history.push('/sourcing')}
        >
          Go Back
        </Button>
      </Alert>
    </Container>
  );
};

export default Success;
