import { useQuery } from 'react-query';
import { AGENT_URL, USER_URL } from '../api/urls';
import { useAxios } from './useAxios';

export const useUsers = () => {
  const axios = useAxios();
  return useQuery(
    'users',
    async () => {
      const { data } = await axios.get(USER_URL);
      const { returned_resultset } = data;
      return returned_resultset;
    },
    {}
  );
};
