import { BASE_API_URL } from '../utils/app-constants';

export const AGENT_URL = `${BASE_API_URL}organisation?organisation_type=agent&limit=1000`;
export const RIDER_URL = `${BASE_API_URL}organisation`;
export const SUPPLIER_URL = `${BASE_API_URL}organisation?organisation_type=supplier`;
export const HANDLERS_URL = `${BASE_API_URL}organisation?organisation_type=handler`;
export const USER_URL = `${BASE_API_URL}user`;
export const LOGIN_URL = `${BASE_API_URL}auth/login`;
export const PRODUCT_URL = `${BASE_API_URL}public/product`;
export const PRODUCT_URL_TYPE = `${BASE_API_URL}producttype`;
export const ROLES_URL = `${BASE_API_URL}role`;
export const UNITS_URL = `${BASE_API_URL}unit`;
export const ORGANISATION_CATEGORY_URL = `${BASE_API_URL}organisationcategory`;
export const ORGANISATION_CATEGORY_TYPE_URL = `${BASE_API_URL}organisationcategorytype`;
export const TRUCK_URL = `${BASE_API_URL}truck`;
export const COOLBOX_URL = `${BASE_API_URL}coolbox`;
export const MAINTENANCE_URL = `${BASE_API_URL}maintenancetype`;
export const APPUSER_URL = `${BASE_API_URL}appuser`;
export const ORDER_URL = `${BASE_API_URL}order`;
export const DOCUMENT_URL = `${BASE_API_URL}organisationdocument`;
export const DOCUMENT_TYPE_URL = `${BASE_API_URL}documenttype`;
export const FEEDBACK_URL = `${BASE_API_URL}feedback`;
export const LOCATION_URL = `${BASE_API_URL}organisationlocation`;
export const PAYMENT_URL = `${BASE_API_URL}payment`;
export const STATS_URL = `${BASE_API_URL}stats`;
export const SOURCING_PRODUCTS = `${BASE_API_URL}sourcing-product`;
