import React from 'react';
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Container,
  Button
} from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';

const Error = () => {
  const history = useHistory();
  return (
    <Container>
      <Alert
        status='error'
        marginTop='2'
        variant='subtle'
        flexDirection='column'
        alignItems='center'
        justifyContent='center'
        height='full'
        textAlign='center'
      >
        <AlertIcon boxSize='40px' mr={0} />
        <AlertTitle mt={4} mb={1} fontSize='lg'>
          Sourcing failed!
        </AlertTitle>
        <AlertDescription maxWidth='sm'>
          Unable to complete your request
        </AlertDescription>
        <Button
          colorScheme='purple'
          marginTop='3'
          onClick={() => history.push('/sourcing')}
          variant='outline'
        >
          Go Back
        </Button>
      </Alert>
    </Container>
  );
};

export default Error;
